angular.module("app")
    .controller("staffAddController", ["$log", "$rootScope", "$state", "$stateParams", "Notification", "staffService", "paymentMethodService", "travelPolicyService", "selectedDepartment", function ($log, $rootScope, $state, $stateParams, Notification, staffService, paymentMethodService, travelPolicyService, selectedDepartment) {

        var vm = this;
        vm.staffReference = undefined;
        vm.organisationReference = undefined;
        vm.submitting = false;
        vm.paymentMethods = [];

        vm.pagedSet = {page: 0};
        vm.pageNotReady = vm.loading = true;
        vm.searching = vm.disableLoadMore = vm.includeInactive = vm.includeLinked = false;
        vm.effectivePolicy = [];
        vm.availablePolicies = [];


        var inactive = false;
        var linked = false;

        vm.staff = {};

        // Exposed functions
        vm.add = add;
        vm.isSelected = isSelected;
        vm.loadMore = loadMore;

        vm.panelTitleExemption = " Exemption";

        function loadMore() {
            listPaymentMethods(vm.pagedSet.page);
        }


        (function init() {

            if (_.isEmpty($stateParams.organisationReference)) {
                selectedDepartment.clearDepartment();
            } else {
                vm.organisationReference = $stateParams.organisationReference;
                selectedDepartment.setDepartment($stateParams.organisationReference);
            }

            if (!_.isEmpty($stateParams.staffReference)) {
                vm.staffReference = $stateParams.staffReference;
                staffService.get(vm.organisationReference, vm.staffReference)
                    .then(function (result) {
                        vm.staff = result;
                        loadTravelPolicies();
                    })
                    ['finally'](function () {
                    vm.pageNotReady = false;
                });
            } else {
                loadTravelPolicies();
            }


        })();

        vm.dayInOrder = function (day) {
            return travelPolicyService.dayOfWeek(day.dayOfWeek);
        };

        function applyTravelPolicy(staff) {
            if (vm.effectivePolicy.length > 0) {
                var policy = vm.effectivePolicy[0];
                if (!policy.isDefault) {
                    staff.travelPolicy = policy;
                }
            } else {
                staff.travelPolicy = undefined;
            }
        }

        function listPaymentMethods(page) {
            vm.disableLoadMore = true;

            var params = {
                page: page,
                inactive: inactive,
                linked: linked,
                children: false,
                organisationReference: vm.organisationReference
            };

            return paymentMethodService.list(params)
                .then(function (pagedSet) {
                    vm.pagedSet = pagedSet;
                    vm.paymentMethods = vm.paymentMethods.concat(pagedSet.results);
                    vm.disableLoadMore = (vm.pagedSet.page >= vm.pagedSet.totalPages);
                }, function(error) {
                    $log.debug(error);
                    vm.disableLoadMore = true;
                })
                ['finally'](function () {
                vm.pageNotReady = false;
            });
        }

        vm.collapseDetail = function () {
            //vm.hideDetail = true;
            $log.debug('Hide detail ', vm.hideDetail);
        };

        function add() {
            var staff = angular.copy(vm.staff);
            vm.submitting = true;
            applyTravelPolicy(staff);
            if (vm.staffReference) {
                updateStaff(staff);
            } else {
                addStaff(staff);
            }
        }

        function updateStaff(staff) {
            staffService.update(vm.organisationReference, vm.staffReference, staff)
                .then(function (result) {
                    $state.go("staff");
                })
                ['catch'](function (resp) {
                reportError(resp);
            })
                ['finally'](function () {
                vm.submitting = false;
            });
        }

        function addStaff(staff) {
            staffService.add(staff)
                .then(function (result) {
                    $state.go("staff");
                })
                ['catch'](function (resp) {
                reportError(resp);
            })
                ['finally'](function () {
                vm.submitting = false;
            });
        }

        function isSelected(organisationPaymentMethodReference) {
            return _.indexOf(vm.staff.organisationPaymentMethodReferences, organisationPaymentMethodReference) > -1;
        }

        function reportError(resp) {
            if (resp.data.messages) {
                _.each(resp.data.messages, function (message) {
                    Notification.error({message: message, delay: 5000});
                });
            } else {
                Notification.error({
                    message: 'There was a problem adding this staff member, please refresh and try again',
                    delay: 5000
                });
            }
        }

        vm.effectivePolicyList = {
            accept: function () {
                if (vm.effectivePolicy.length >= 1) {
                    return false;
                } else {
                    return true;
                }
            }
        };

        function loadTravelPolicies() {
            if (!_.isUndefined(vm.staff.travelPolicy)) {
                vm.effectivePolicy.push(vm.staff.travelPolicy);
            }

            return travelPolicyService.list(false)
                .then(function (results) {
                    $log.debug('policies %o', results);
                    if (results) {
                        results.forEach(function (policy) {
                            if (policy.isDefault && vm.effectivePolicy.length === 0) {
                                vm.effectivePolicy.push(policy);
                            } else {
                                if (_.isUndefined(vm.staff.travelPolicy) || policy.travelPolicyReference != vm.staff.travelPolicy.travelPolicyReference) {
                                    vm.availablePolicies.push(policy);
                                }
                            }
                        });
                    }

                    if (vm.effectivePolicy.length === 0) {
                        return travelPolicyService.getDefaultPolicy()
                            .then(function (result) {
                                if (!_.isUndefined(result)) {
                                    vm.effectivePolicy.push(result);
                                    vm.availablePolicies.push({});
                                }
                            });
                    }
                    if (vm.effectivePolicy.length > 0) {
                        vm.availablePolicies.push({});
                    }
                })['finally'](function() {
                vm.pageNotReady = false;
            });
        }
    }]);